import React from "react"

const about = () => {
  return (
    <section id="about" className="container">
      <h2>О компании</h2>
      <p>
        Компания ИННО специализируется на проектировании изготовлении и поставке
        специального технологического оборудования, комплектующих промышленного
        и общего назначения, вспомогательного оборудования запасных частей
        Разработка проекта, производство, при необходимости совместно с
        партнерами предприятиями-изготовителями, согласование проекта с
        заказчиком или инжиниринговыми компаниями. Индивидуальный подход к
        каждому проекту и заказчику позволяет работать в широкой географии и с
        наилучшим конечным результатом. Поставляемое оборудование сопровождается
        необходимыми сертификатами и разрешениями в соответствие международными
        нормами и требованиями законодательства РФ.
      </p>
    </section>
  )
}

export default about
